* {
  margin: 0;
  padding: 0;
  font-family: "poppins", sans-serif;
  box-sizing: border-box;
}

body {
  background: #efefef;
  /* background: var(--body-color); */
  /* transition: background 0.3s; */
}